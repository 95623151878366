.App {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1080px;
}

.App-controls {
  grid-column: 1 / span 5;
}

.media {
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  padding: 1rem;
}

.full {
  grid-column: span 12;
}

.center {
  text-align: center;
}

.tracklist {
  margin: 0;
  list-style: none;
  padding-left: 0;
  grid-column: 1 / span 5;
}

.track {
  padding: 0.5rem;
}
.track:nth-of-type(even) {
  background-color: whitesmoke;
}

.fa {
  cursor: pointer;
}

.title {
  color: slategray;
  text-decoration: underline;
  cursor: pointer;
}

.title:visited {
  color: slategray;
}

.title:hover {
  color: #666666;
}

.track.active {
  background-color: gainsboro;
}

.player {
  grid-column: 7 / span 6;
  height: 320px;
  width: auto;
  display: inline-block;
}

video:focus {
  outline: none;
}

.video {
  width: 100%;
}
